import React, { useContext, useEffect, useState } from "react";
import Axios from "../../Axios";
import Loader from "../../Loader";
import CaroselBanner from "../../components/CaroselBanner";
import Toast from "../../Tost";
import { AuthContext } from "../../AuthProvider";
import Dropdown from "react-bootstrap/Dropdown";

function Maintenance() {
  const [isLoading, setIsLoading] = useState(false);
  const [maintenance, setMaintenance] = useState([]);
  const [maintenaceBanner, setMaintenaceBanner] = useState([]);
  const { userToken } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedStore, setSelectedStore] = useState("Select Store");
  const [storeData, setStoreData] = useState({});
  const [storeId, setStoreId] = useState()
  const [selectedPrice, setSelectedPrice] = useState(0);

  // Get the current date
const today = new Date();

// Extract the day, month, and year
const day = String(today.getDate()).padStart(2, '0'); // Pads single digit day with a leading zero
const month = String(today.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-indexed
const year = String(today.getFullYear()); // Gets the last two digits of the year

// Format the date as dd/mm/yy
const currentDate = `${year}-${month}-${day}`;





  const handleGetMaintenance = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get(
        "https://caross.in/admin/api/user/maintenance",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response?.data;
        setMaintenance(data?.maintenances || []);
      } else {
        Toast("Failed to load maintenance data");
      }
    } catch (err) {
      Toast("An error occurred while fetching maintenance data");
    } finally {
      setIsLoading(false);
    }
  };

  const getPdiBanner = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get("/get_pdi_banner");
      if (response.status === 200) {
        const data = response?.data;
        setMaintenaceBanner(data?.banners || []);
      } else {
        Toast("Failed to load banners");
      }
    } catch (err) {
      Toast("An error occurred while fetching banners");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookNowMaintenance = async () => {
     
    try {
      setIsLoading(true);
      const res = await Axios.post(
        "https://caross.in/admin/api/user/order-maintenance",
        {
          order_id: selectedOrderId,
          date: selectedDate,
          store_id: storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(res.data);
      if (res.status === 200) {
       
        console.log("Maintenance booked successfully...");
        Toast("Service booked successfully",200);
      
      }
    } catch (error) {
      console.log("Something went wrong", error);
      Toast("Failed to book service");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetMaintenance();
    getPdiBanner();
  }, []);
  

  const getStoreNames = async () => {
    try {
      setIsLoading(true);
      if (userToken) {
        const response = await Axios.get("/get-store-address", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        // console.log(response);
        
        if (response?.status === 200) {
         
          const storeAddresses = response.data.store_address; // assuming response contains an object of store addresses
          setStoreData(storeAddresses);
        }
      }
    } catch (err) {
      console.error("Failed to fetch store names", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStoreNames();
  }, []);

  const handleSelect = (eventKey) => {
    const selectedStoreName = storeData[eventKey];
    setSelectedStore(selectedStoreName);
    
    console.log("Selected Store ID:", eventKey);
    setStoreId(eventKey)
  };

  

  const PayNow = async(price) => {
    
    setIsLoading(true);
    

    const options = {
      description: "Maintenance fees ",
      image:'https://i.imgur.com/3g7nmJC.png',
      currency: "INR",
      key: 'rzp_live_cckzy2AStiy992', 
      amount:price *100 ,
      name: "Caross",
      // order_id: "", // This is a sample Order ID. Pass the `id` obtained in the response of the API call in the backend.
      description:'Thanks for purchasing',
      handler: function (response) { 
        // Handle the success response after payment
        console.log("PayNow check response",response);
        Toast("Payment successful",200);
        handleBookNowMaintenance();
        setStoreData('')
        setStoreId('') // Call the maintenance booking function after payment
      },
      
      prefill: {
        name: "Caross",
   
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#F37254",
      },
    };
    
    console.log("second",options.amount)
  
    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      // Handle the failure response
      console.error(response.error);
      Toast("Payment failed");
      setIsLoading(false);
    });
  
    rzp1.open();
    setIsLoading(false);
  };
  


  return (
    <>
      {isLoading && <Loader />}
      <div className="container nav-margin">
        <div className="row">
          <div className="col-12">
            <CaroselBanner banners={maintenaceBanner} />
          </div>
          <div className="col-12 py-4">
            <h3
              style={{ fontSize: "30px" }}
              className="red-t text-center fw-bold"
            >
              Maintenance
            </h3>
          </div>
          {maintenance.map((val) => (
            <div key={val.id} className="col-12 col-md-4 p-2 mb-2">
              <div className="maintenance-card border rounded p-3">
                <div className="pb-2">
                  <img
                    src={
                      val.car_image ||
                      "https://shopninja.in/anurag/caross/public/storage/brand-models/October2023/N3g4piOboqVr0TbQamuc.jpg"
                    }
                    className="img-fluid"
                    alt={val.name || "Car Image"}
                  />
                </div>
                <h4 className="fw-bold">{val.name}</h4>
                <p
                  className="p-0 mb-0"
                  style={{ textAlign: "justify", fontSize: "13px" }}
                >
                  {val?.discription}
                </p>
                <div>
                  <p>{val?.left}</p>
                </div>
                <div>{<p>Price : {val?.price}</p>}</div>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                  <div className="mb-2">
                    <span className="" style={{ fontSize: "13px" }}>
                      Expiry Date
                    </span>
                    : <span style={{ fontSize: "13px" }}>{val.date}</span>
                  </div>
                </div>
                {val.left===0 ||  val.date<currentDate ? (
                  <button className="red-btn-disable" disabled>
                    Book Now
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      className="red-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      onClick={() => {
                        setSelectedOrderId(val.order_id);
                        setSelectedPrice(val.price); // Set the selected price
                      }}  
                    >
                      Book Now
                    </button>
                    <div
                      className="modal fade"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex={-1}
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1
                              className="modal-title fs-5"
                              id="staticBackdropLabel"
                            >
                              Book Now
                            </h1>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <div className="modal-body d-flex justify-content-around ">
                          <div>
                              <Dropdown onSelect={handleSelect}>
                                <Dropdown.Toggle
                                  variant="white"
                                  id="dropdown-basic"
                                  className="card-shadow p-2"
                                >
                                  {selectedStore}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {Object.keys(storeData).map((id) => (
                                    <Dropdown.Item key={id} eventKey={id}>
                                      {storeData[id]}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div>
                              <input
                                type="date"
                                className="p-2 card-shadow border rounded"
                                value={selectedDate}
                                onChange={(e) =>
                                  setSelectedDate(e.target.value)
                                }
                              />
                            </div>
                          
                          </div>
                          <div className="modal-footer">
                          {
                            selectedDate&& selectedStore ?(
                    
                            <button
                              type="button"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              className="btn bg-danger text-white"
                              onClick={() => PayNow(selectedPrice)}
                            >
                              {isLoading ? <Loader /> : "Pay Now"} 
                            </button>):(
                              <button
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className="btn bg-danger text-white "
                              >
                                Select Store & Date
                              </button>
                            )
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Maintenance;
