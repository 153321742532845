import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

function CategoryCardService({ logo, SetChanges }) {
  const { pathname } = useLocation();

  const isCurrentService = pathname === `/services/${logo?.slug}`;

  useEffect(() => {
    if (isCurrentService && !document.getElementById("faqSchema")) {
      const script = document.createElement("script");
      script.id = "faqSchema";
      script.type = "application/ld+json";
      script.text = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          {
            "@type": "Question",
            name: "What is cost of Denting and Painting.",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Denting and Painting cost is variable and can be given after the final service.",
            },
          },
          {
            "@type": "Question",
            name: "How to service my car for denting",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Come with your car your nearest caross showroom and get it done",
            },
          },
        ],
      });
      document.body.appendChild(script);
    }
  }, [isCurrentService]);

  return (
    <>
      <div
        key={logo?.id}
        className="col pb-3 pb-md-1 col-lg-3 col-6 d-flex justify-content-center align-items-center mb-5"
      >
        <Link
          to={`/services/${logo?.slug}`}
          style={{
            fontSize: "7px",
            width: "100%",
            height: "auto",
            backgroundColor: isCurrentService ? "" : "#474747",
            borderBottom: isCurrentService ? "2px solid red" : "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => SetChanges(false)}
          className="pointer p-2 bg-white d-flex flex-column justify-content-center align-items-center"
        >
          <img
            src={logo?.icon}
            width="30px"
            height="30px"
            className=""
            alt=""
          />
          <p
            style={{
              fontSize: "7px",
              color: isCurrentService ? "#DE3820" : "#474747",
              lineHeight: "13px",
              marginTop: "5px",
            }}
            className="categories-text text-center d-block"
          >
            {logo?.name}
          </p>
        </Link>
      </div>
    </>
  );
}

export default CategoryCardService;
