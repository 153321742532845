import React from "react";
import TitleName from "./TitleName";

const WhyChoose = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <TitleName
            title="Why Choose Caross "
            subtitle="for Ceramic Coating?"
          />

          <div>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Experienced and Skilled Technicians:
              </span>{" "}
              Our team consists of experts trained in the latest ceramic coating
              techniques.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">Use of High-Quality Materials:</span> We
              only use top-grade products to ensure the best results.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">Advanced Application Techniques:</span>{" "}
              Our cutting-edge methods ensure a flawless finish.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">Customer Satisfaction Guarantee:</span>{" "}
              We are committed to exceeding your expectations with every
              application.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <TitleName
            title="The Ceramic Coating "
            subtitle="Application Process"
          />

          <div>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Initial Consultation and Assessment:
              </span>{" "}
              We assess your vehicle’s condition and discuss your needs.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Preparing Your Car for Ceramic Coating:{" "}
              </span>{" "}
              Thorough cleaning and surface preparation.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Applying the Coating: Step-by-Step Guide:{" "}
              </span>{" "}
              Detailed application process to ensure even coverage.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Post-Application Care and Maintenance:{" "}
              </span>{" "}
              Tips and guidelines to maintain your car’s new look.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
