import React from "react";
import "./Common.css";
import { Link } from "react-router-dom";
const ThePPFInstallationProcess = () => (
  <>
    <div>
      <div className="wrap">
        <div className="col-12 my-2 d-flex justify-content-center align-items-center">
          <h2 className="text-center text-danger mt-2 fw-bold">
            THE PPF INSTALLATION PROCESS
          </h2>
        </div>
        <div className="col col1"></div>
        <div className="col col2">
          <div className="circle circle1">
            <h3>
              <span>
                <b>Consultation</b>{" "}
              </span>
            </h3>
            <div className="shim shim1"></div>
          </div>
          <div className="content">
            <p className="parraTitle">1. Initial Consultation and Assessment</p>
            <p className="text-dark">
              We start with a detailed consultation to understand your needs and
              assess your vehicle for the best PPF solution.
            </p>
          </div>
        </div>
        <div className="col col1">
          <div className="circle circle2">
            <h3>
              <span>
                <b>Preparation </b>
              </span>
            </h3>
            <div className="shim shimx shim2"></div>
          </div>
          <div className="content">
            <p className="parraTitle">2. Preparing Your Car for PPF</p>
            <p className="text-dark">
              Our technicians meticulously clean and prepare your car's surface
              to ensure the film adheres perfectly.
            </p>
          </div>
        </div>
        <div className="col col2"></div>
        <div className="col col1"></div>
        <div className="col col2">
          <div className="circle circle3">
            <h3>
              <span>
                <b>Application</b>
              </span>
            </h3>
            <div className="shim shim3"></div>
          </div>
          <div className="content">
            <p className="parraTitle">
              3. Applying the Film: Step-by-Step Guide
            </p>
            <p className="text-dark">
              We apply the PPF with precision, using advanced techniques to
              avoid bubbles and ensure a smooth finish.
            </p>
          </div>
        </div>
        <div className="col col1">
          <div className="circle circle4">
            <h3>
              <span>
                <b> Installation</b>
              </span>
            </h3>
            <div className="shim shimx shim4"></div>
          </div>
          <div className="content">
            <p className="parraTitle">
              4. Post-Installation Care and Maintenance
            </p>
            <p className="text-dark">
              We provide guidelines on how to care for your PPF, ensuring it
              stays in top condition for years to come.
            </p>
          </div>
        </div>
        <div className="col col2"></div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Link to="/services/paint-protection">
          <button
            style={{
              backgroundColor: "#b42424",
              color: "#fff",
              padding: "13px 50px",
              fontSize: "15px",
            }}
            className="btn btn mt-5"
          >
            Book Your PPF Consultation Now
          </button>
        </Link>
      </div>
    </div>
  </>
);

export default ThePPFInstallationProcess;
