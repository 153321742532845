import React from "react";
import TitleName from "./TitleName";
import "./CommonCeramic.css";

const CeramicCard = ({ title, des, imgUrl }) => {
  return (
    <div
      className="card p-3 w-100"
      style={{
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        border: "none",
        height: "400px",
      }}
    >
      <div className="ceramic-card">
        <img src={imgUrl} alt={title} className="w-100" />
        <div className="card-content">
          <h4 className="fw-bold mt-2">{title}</h4>
          <p className="mt-3">{des}</p>
        </div>
      </div>
    </div>
  );
};

const CeramicCoatingOptions = () => {
  return (
    <div className="container">
      <TitleName title="OUR CERAMIC" subtitle="COATING OPTIONS" />
      <div className="row">
        <div className="col-md-4 mt-3">
          <CeramicCard
            imgUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrICdLbDOG8r8ettqKI22aOFFAb2K1lwlaz8_rHzt0ZxVvLWFudEpycIf6bxh3ztyNyXM&usqp=CAU"
            title="Standard Ceramic Coating"
            des="Basic protection with excellent durability."
          />
        </div>
        <div className="col-md-4 mt-3">
          <CeramicCard
            imgUrl="https://img.freepik.com/free-photo/beautiful-car-polishing-service_23-2149212247.jpg"
            title="Premium Ceramic Coating"
            des="Enhanced formulation for superior protection and gloss."
          />
        </div>
        <div className="col-md-4 mt-3">
          <CeramicCard
            imgUrl="https://www.shutterstock.com/image-photo/car-service-worker-applying-nano-600nw-1814908295.jpg"
            title="Custom Ceramic Coating Packages"
            des="Tailored solutions to meet specific needs and preferences."
          />
        </div>
      </div>
    </div>
  );
};

export default CeramicCoatingOptions;
