import React from "react";
import Cookies from "js-cookie";
import {  Link, useNavigate } from "react-router-dom";
import "./StoreNavbar.css"
import logo from "../../asets/logo.png"

const StoreNavbar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove("storeToken"); // Remove the token from cookies
    Cookies.remove("state"); // Remove the token from cookies
    navigate("/store-login"); // Redirect to login page
  };
  return (
    <div>
  
      <>
        <nav className="navbar nav-shadow bg-white px-1 mt-0  navbar-expand-lg  nav-container ">
          <div className="container-fluid">
            <img src={logo} className="navbar-brand fw-bold"/>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse  navbar-collapse bg-white p-2 mt-1"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/store-management">
                   My Expenses
                  </Link>
                </li>
                <li className="nav-item text-black">
                  <Link className="nav-link text-black" to="/my-store-order">
                   My Orders
                  </Link>
                </li>
              </ul>

              <button
              type="button"
              className="btn"
              onClick={handleLogout}
              style={{ backgroundColor: "#dc3545", color: "white" }}
            >
              <i className="bi bi-box-arrow-right"></i> Logout
            </button>
              
            </div>
          </div>
        </nav>
      </>
    </div>
  );
};

export default StoreNavbar;
