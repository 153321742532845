import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Toast from "../../Tost";
import Cookies from "js-cookie";
import Loader from "../../Loader";

const StoreLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleStoreLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await axios.post("https://caross.in/admin/api/user/store-login", {
        email,
        password,
      });

      if (res.data.status === 200) {
        const { token, address, message } = res.data;

        if (token) {
          Cookies.set("storeToken", token, { expires: 7 });
          Cookies.set("state", address, { expires: 7 });

          Toast(message, 200);
          navigate("/store-management");
        } else {
          Toast("Token not found");
        }
      } else {
        Toast(res.data.message);
      }
    } catch (error) {
      console.error("Something went wrong", error);
      Toast("Store Login Failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="bg-light py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-md-center mt-5">
            <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
              <div className="bg-white p-4 p-md-5 rounded shadow-sm">
                <h3 className="mb-5">Log in</h3>
                <form onSubmit={handleStoreLogin}>
                  <div className="row gy-3 gy-md-4">
                    <div className="col-12">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="password" className="form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 mt-5">
                      <div className="d-grid">
                        <button
                          className="btn btn-lg"
                          type="submit"
                          style={{ backgroundColor: "#b8242a", color: "white" }}
                        >
                          Log in
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreLogin;
