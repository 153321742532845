import React, { useEffect, useState } from "react";
import axios from "axios";
// import { FaEdit, FaTrash } from 'react-icons/fa';
import StoreNavbar from "./StoreNavbar";
import Cookies from "js-cookie";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";
// import Toast from "../../Tost";

const MyStoreOrder = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const fetchOrders = async () => {
    const token = Cookies.get("storeToken");
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://caross.in/admin/api/user/store-orders",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);
      if (response.status === 200) {
        const data = response.data.data;
        setData(Array.isArray(data) ? data.reverse(): []);
      }
    } catch (err) {
      console.error("Failed to fetch orders", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeStatus = async (orderId, newStatus) => {
    setIsLoading(true);
    const token = Cookies.get("storeToken");
    fetchOrders();
    try {
      await axios.post(
        "https://caross.in/admin/api/user/edit-store-order",
        {
          order_stage: newStatus,
          order_id: orderId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
    } catch (error) {
      console.error("Failed to update status", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (!Cookies.get("storeToken")) {
      navigate("/store-login"); // Redirect to login page if token is not found
    }
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <StoreNavbar />
      <div className="mt-5 container">
        <h3>Submitted Orders</h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Order ID</th>
                <th>Customer</th>
                <th>Address</th>
                <th>Services</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>PickUp Date</th>
                <th>Delivery Date</th>
                <th>Status</th>
                <th>Car</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data) && data.length > 0 ? (
                data.reverse().map((order, index) => (
                  <tr key={order.id}>
                    <td>{index + 1}</td>
                    <td>{order.order_id}</td>
                    <td>{order.customer_name || "N/A"}</td>
                    <td>{order.address}</td>
                    <td>{order.service_name}</td>
                    <td>{order.amount}</td>
                    <td>{order.order_date}</td>
                    <td>{order.order_pickup_date}</td>
                    <td>{order.order_delivery_date}</td>
                    <td>
                      <select
                        value={order.order_stage}
                        onChange={(e) =>
                          handleChangeStatus(order.order_id, e.target.value)
                        }
                      >
                        <option value="1">Confirmed</option>
                        <option value="2">Ready to Pickup</option>
                        <option value="3">Picked</option>
                        <option value="4">Ready to Service</option>
                        <option value="5">Service Done</option>
                      </select>
                    </td>
                    <td>{order.car_details}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center">
                    No orders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyStoreOrder;
