import React from "react";
import paintProtectionTwo from "../../../src/asets/paintProtectionTwo.png";
import paintProtectionThree from "../../../src/asets/paintProtectionThree.png";

const MultipleBenfits = ({ title, description }) => {
  return (
    <>
      <div className="row mb-4">
        <div className="col-12 d-flex align-items-center">
          <img
            src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcS2hn5E_L2a0vLEVivGh4ao22fpOa4Uro4EQ9Ayvf6PPuWZveXT"
            alt=""
            className="img-fluid me-3"
            style={{
              width: "1.5rem",
              height: "1.5rem",
              objectFit: "contain",
            }}
          />
          <p className="fw-bold mb-0">{title}</p>
        </div>
        <div className="col-11">
          <p className="mx-3">{description}</p>
        </div>
      </div>
    </>
  );
};

const CommonAccordin = () => {
  return (
    <div className="container mt-3 mb-3">
      <div className="row mt-4">
        <div className="col-md-6 mt-3 d-flex justify-content-center align-items-center">
          <img src={paintProtectionThree} alt="" className="img-fluid w-100" />
        </div>
        <div className="col-md-6 mt-3 d-flex justify-content-center align-items-center">
          <div className="accordion w-100" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button personalaccrodian"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h3 style={{ fontSize: "18px" }}>
                    Garware Paint Protection Film
                  </h3>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Garware Car Paint Protection Film (PPF) offers unparalleled
                  protection to keep your vehicle's finish in pristine
                  condition. Engineered for superior durability, this nearly
                  invisible film ensures your car's design and colour remain
                  untouched while shielding critical areas like front bumpers,
                  hoods, side mirrors, door handle cavities, door edges, rocker
                  panels, and rear fender panels. Choosing Garware PPF means
                  your vehicle stays looking fresh for longer, with the added
                  benefit of potentially increasing its resale value.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  style={{ backgroundColor: "#b8242a", color: "#fff" }}
                  className="accordion-button collapsed personalaccrodian"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h3 style={{ fontSize: "18px" }}>
                    PaintGuard Paint Protection Film
                  </h3>
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse">
                <div className="accordion-body">
                  <strong>
                    PaintGuard Car Paint Protection Film (PPF) is an outstanding
                    solution to maintain your vehicle's showroom-quality finish.
                  </strong>
                  This virtually invisible film preserves your car's design and
                  colour while protecting front bumpers, hoods, side mirrors,
                  door handle cavities, door edges, rocker panels, and rear
                  fender panels. By using PaintGuard PPF, your vehicle will look
                  newer for longer and its resale value can be enhanced in the
                  future.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  style={{ backgroundColor: "#b8242a", color: "#fff" }}
                  className="accordion-button collapsed personalaccrodian"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  <h3 style={{ fontSize: "18px" }}>Custom PPF Packages</h3>
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse">
                <div className="accordion-body">
                  <strong>
                    We offer custom PPF packages tailored to your specific
                    needs,
                  </strong>
                  whether you want full-body car protection or coverage for
                  high-impact areas.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const BenefitsOfPaintProtection = () => {
  return (
    <div>
      <div>
        <div className="row mb-3">
          <h2 className="text-center text-danger mt-4 fw-bold">
            BENEFITS OF PAINT PROTECTION FILM
          </h2>
          <div className="col-md-6 mt-3 order-md-2 d-flex justify-content-center">
            <img
              src={paintProtectionTwo}
              alt="Paint Protection"
              className="img-fluid w-100 mt-3"
            />
          </div>
          <div className="col-md-6 mt-3 order-md-1">
            <div>
              <MultipleBenfits
                title="1) Superior Protection Against Scratches and Chips"
                description="Delhi NCR roads can be tough on your car. PPF absorbs the impact of small stones, debris, and other elements that can cause scratches and chips, ensuring your car's paint remains intact."
              />

              <MultipleBenfits
                title="2) UV Resistance and Paint Fading Prevention"
                description="The intense sun in Delhi NCR can cause your car’s paint to fade or discolor over time. PPF offers excellent UV resistance, keeping your car's paint vibrant and fresh."
              />
              <MultipleBenfits
                title="3) Self-Healing Properties of PPF"
                description="With advanced self-healing properties, minor scratches and swirl marks disappear with heat, keeping your car looking flawless despite the rigors of daily commuting."
              />

              <MultipleBenfits
                title="4) Preserving Your Car's Resale Value"
                description="Maintaining the original paint condition is crucial for preserving your car's resale value. PPF ensures your car looks new, making it more attractive to potential buyers."
              />
              <MultipleBenfits
                title="5) Superior Protection Against Scratches and Chips"
                description="Delhi NCR roads can be tough on your car. PPF absorbs the impact of small stones, debris, and other elements that can cause scratches and chips, ensuring your car's paint remains intact."
              />
              <MultipleBenfits
                title="6) Superior Protection Against Scratches and Chips"
                description="Delhi NCR roads can be tough on your car. PPF absorbs the impact of small stones, debris, and other elements that can cause scratches and chips, ensuring your car's paint remains intact."
              />
            </div>
          </div>
        </div>
        <CommonAccordin />
      </div>
    </div>
  );
};

export default BenefitsOfPaintProtection;
