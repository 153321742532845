import React from "react";
import TitleName from "./TitleName";
import instCer from "../../asets/ceramicinsta.jpg"
import instCer2 from "../../asets/ceramicinsta2.jpg"

const CeramicTestimonials = () => {
  return (
    <div className="container">
      <TitleName title="" subtitle="TESTIMONIALS" />
      <div className="d-flex justify-content-center flex-wrap gap-3">
        <div className="card" style={{ width: "25rem" }}>
          <img
          src={instCer}
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">John D</h5>
            <h5 className="card-title">Hear from Our Satisfied Clients</h5>
            <p className="card-text">My car has never looked better</p>
          </div>
        </div>
        <div className="card" style={{ width: "25rem" }}>
          <img
          src={instCer2}
          className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Ravi Poddar</h5>
            <h5 className="card-title">Real-life Examples of Our Work</h5>
            <p className="card-text">
              See before-and-after photos of cars treated with our ceramic
              coatings.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CeramicTestimonials;
