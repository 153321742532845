import React from "react";

const TitleName = ({ title, subtitle }) => {
  return (
    <div className="container py-3">
      <h2 className="text-center fw-bold mt-5">
        {title} <span style={{ color: "#b8242a" }}>{subtitle}</span>
      </h2>
    </div>
  );
};

export default TitleName;
