import React from "react";
import { Helmet } from "react-helmet";

function TermAndCondition() {
  return (
    <>
      <Helmet>
        <title>
          Caross Terms and Conditions: Understanding Our Service Agreement
        </title>
        <meta
          name="description"
          content="Familiarize yourself with the terms and conditions of Caross services. Our service
agreement outlines the rights and responsibilities of both parties, ensuring a transparent and fair relationship between you and Caross.
"
        ></meta>
      </Helmet>
      <div className="container-fluid nav-margin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="my-4 text-center red-t">Terms & Conditions</h3>
              <p className="mt-3">
                The Website Owner, including subsidiaries and affiliates
                (“Website” or “Website Owner” or “we” or “us” or “our”) provides
                the information contained on the website or any of the pages
                comprising the website (“website”) to visitors (“visitors”)
                (cumulatively referred to as “you” or “your” hereinafter)
                subject to the terms and conditions set out in these website
                terms and conditions, the privacy policy and any other relevant
                terms and conditions, policies and notices which may be
                applicable to a specific section or module of the website.
              </p>
              <p className="mt-3">
                Welcome to our website. If you continue to browse and use this
                website you are agreeing to comply with and be bound by the
                following terms and conditions of use, which together with our
                privacy policy govern ECOTIONS VENTURE PRIVATE LIMITED''s
                relationship with you in relation to this website.
              </p>
              <p className="mt-3">
                The term 'ECOTIONS VENTURE PRIVATE LIMITED' or 'us' or 'we'
                refers to the owner of the website whose registered/operational
                office is D 52 Street No 8 Rajnagar 2 Palam Colony South West
                Delhi DELHI 110077. The term 'you' refers to the user or viewer
                of our website.
              </p>

              <h4 className="mt-5 mb-3">
                The use of this website is subject to the following terms of
                use:
              </h4>
              <ul>
                <li style={{ listStyleType: "initial" }}>
                  The content of the pages of this website is for your general
                  information and use only. It is subject to change without
                  notice.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  Neither we nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or suitability of the information and materials
                  found or offered on this website for any particular purpose.
                  You acknowledge that such information and materials may
                  contain inaccuracies or errors and we expressly exclude
                  liability for any such inaccuracies or errors to the fullest
                  extent permitted by law.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  Your use of any information or materials on this website is
                  entirely at your own risk, for which we shall not be liable.
                  It shall be your own responsibility to ensure that any
                  products, services or information available through this
                  website meet your specific requirements.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  This website contains material which is owned by or licensed
                  to us. This material includes, but is not limited to, the
                  design, layout, look, appearance and graphics. Reproduction is
                  prohibited other than in accordance with the copyright notice,
                  which forms part of these terms and conditions.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  All trademarks reproduced in this website which are not the
                  property of, or licensed to, the operator are acknowledged on
                  the website.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  Unauthorized use of this website may give rise to a claim for
                  damages and/or be a criminal offense.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  From time to time this website may also include links to other
                  websites. These links are provided for your convenience to
                  provide further information.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  You may not create a link to this website from another website
                  or document without ECOTIONS VENTURE PRIVATE LIMITED’s prior
                  written consent.
                </li>
                <li style={{ listStyleType: "initial" }}>
                  Your use of this website and any dispute arising out of such
                  use of the website is subject to the laws of India or other
                  regulatory authority.
                </li>
              </ul>
              <p className="mt-4">
                We as a merchant shall be under no liability whatsoever in
                respect of any loss or damage arising directly or indirectly out
                of the decline of authorization for any Transaction, on Account
                of the Cardholder having exceeded the preset limit mutually
                agreed by us with our acquiring bank from time to time
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermAndCondition;
