import React, { useEffect, useState } from "react";
import axios from "axios";

const FrequentlyAskQuestion = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get("https://caross.in/admin/api/user/get_faqs")
      .then((response) => {
        const data = response.data;
        // Access the faqs array from the response object
        if (data.faqs && Array.isArray(data.faqs)) {
          setFaqs(data.faqs);
        } else {
          throw new Error("Data is not an array");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading)
    return (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="alert alert-danger" role="alert">
        {error.message}
      </div>
    );

  return (
    <div className="container mt-4 mb-4">
      <h2 className="mb-4 text-center fw-bold">
        FREQUENTLY <span style={{ color: "#b8242a" }}>ASKED QUESTIONS</span>
      </h2>
      <div className="accordion" id="accordionExample">
        {faqs.map((faq, index) => (
          <div className="accordion-item" key={faq.id}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className={`accordion-button ${index !== 0 ? "collapsed" : ""}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded={index === 0 ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                {faq.ques}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${
                index === 0 ? "show" : ""
              }`}
              aria-labelledby={`heading${index}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskQuestion;
