import React from "react";
import { Helmet } from "react-helmet";

function ShippingAndDeliveryPolicy() {
  return (
    <>
      <Helmet>
        <title>Shipping and Delivery Policy | Caross</title>
        <meta
          name="description"
          content="Discover Caross's shipping and delivery policy to understand how your products are shipped, the delivery process, and related terms and conditions."
        ></meta>
      </Helmet>
      <div className="container-fluid nav-margin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="my-4 red-t">Shipping & Delivery Policy</h3>
              <p className="my-3">
                For International buyers, orders are shipped and delivered
                through registered international courier companies and/or
                International speed post only. For domestic buyers, orders are
                shipped through registered domestic courier companies and /or
                speed post only. Orders are shipped within 3-5 days or as per
                the delivery date agreed at the time of order confirmation and
                delivering of the shipment subject to Courier Company / post
                office norms. ECOTIONS VENTURE PRIVATE LIMITED is not liable for
                any delay in delivery by the courier company / postal
                authorities and only guarantees to hand over the consignment to
                the courier company or postal authorities within 3-5 days from
                the date of the order and payment or as per the delivery date
                agreed at the time of order confirmation. Delivery of all orders
                will be to the address provided by the buyer. Delivery of our
                services will be confirmed on your mail ID as specified during
                registration. For any issues in utilizing our services you may
                contact our helpdesk on 8929227077 or care@caross.in
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingAndDeliveryPolicy;
