import React from "react";
import "./Common.css";

const InstallationProcess = () => (
  <div className="container">
    <div className="installation-process">
      <h2 className="text-center text-danger mt-4 fw-bold">
        INSTALLATION PROCESS
      </h2>
      <div className="row mt-4">
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step purchase-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>1. Experienced and Skilled Technicians</h3>
            <p>
              Our team of technicians is highly trained and experienced in PPF
              installation, ensuring precise and flawless application.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step call-scheduled-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>2. Use of High-Quality Materials</h3>
            <p>
              We use only the best quality PPF materials from trusted
              manufacturers to guarantee optimal protection and longevity.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step install-test-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>3. Advanced Installation Techniques</h3>
            <p>
              Our installation process utilises the latest techniques and tools
              to achieve a seamless, bubble-free finish that adheres perfectly
              to your car's contours.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step finished-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>4. Customer Satisfaction Guarantee</h3>
            <p>
              At Caross, we prioritise customer satisfaction, offering
              warranties and aftercare support to ensure you are completely
              happy with our services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InstallationProcess;
