import React from "react";

const ThankYou = () => {
  return (
    <>
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "90vh",
          display: "grid",
          placeContent: "center",
        }}
      >
        <div className="d-flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="currentColor"
            class="bi bi-check2-circle"
            viewBox="0 0 16 16"
            style={{ fontSize: "xxx-large", fontWeight: "bold" }}
          >
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
          </svg>
          <h1
            style={{
              color: "black",
              fontSize: "xxx-large",
              fontWeight: "bold",
            }}
          >
            Thank You
          </h1>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
