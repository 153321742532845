import React from "react";
import ceramic1 from "../../asets/ceramic1.jpg";
import ceramic2 from "../../asets/ceramic2.jpg";
import ceramic3 from "../../asets/ceramic3.jpg";
import ceramic4 from "../../asets/ceramic4.jpg";
import ceramic5 from "../../asets/ceramic5.jpg";
import ceramic6 from "../../asets/ceramic6.jpg";
import TitleName from "./TitleName";

const CeramicSocial = () => {
  const imageSources = [
    ceramic1,
    ceramic2,
    ceramic3,
    ceramic4,
    ceramic5,
    ceramic6,
  ];

  const instagramLinks = [
    "https://www.instagram.com/reel/C8BzsRbpwbi/page1",
    "https://www.instagram.com/reel/C8ZZNX5tGyT/page2",
    "https://www.instagram.com/reel/C7yzmhOP3Ll/page3",
    "https://www.instagram.com/reel/C8BzsRbpwbi/page4",
    "https://www.instagram.com/reel/C9PvtLXPbya/page5",
    "https://www.instagram.com/reel/C8_1VWfvKE0/page6",
  ];

  return (
    <div>
      <TitleName title="DELIVERED CERAMIC" subtitle="COATING CARS" />
      <div className="d-flex flex-wrap justify-content-center">
        {imageSources.map((src, index) => (
          <div key={index} className="card mx-3 my-2">
            <a
              href={instagramLinks[index]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={src}
                className="card-img-top zoom-on-hover"
                alt={`Instagram ${index + 1}`}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CeramicSocial;
