import React from "react";
import TitleName from "./TitleName";
import "./CommonCeramic.css";
import handcoating from "../../asets/Rectangle 1.png";
import handcoating1 from "../../asets/Rectangle 2.png";
import handcoating2 from "../../asets/Rectangle 3.png";

const BenfitsData = () => {
  return (
    <div className="mt-3">
      <div
        class="card w-100 p-3"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "none",
        }}
      >
        <div className="p-4">
          <h3 class="card-title text-danger fw-bold">Benefits</h3>
          <p className="fs-5 mt-2">
            <strong>Long-Lasting Protection:</strong> Extends the life of your
            car's paint.
          </p>
          <p className="fs-5 mt-3">
            <strong>Enhanced Gloss and Shine:</strong> Gives your car a superior,
            glossy finish.
          </p>
          <p className="fs-5 mt-3">
            <strong>Scratch and Swirl Resistance:</strong> Reduces the risk of
            minor scratches and swirls.
          </p>
          <p className="fs-5 mt-3">
            <strong>Cost-Effective in the Long Run:</strong> Reduces the need for
            frequent waxing and detailing.
          </p>
          <p className="fs-5 mt-3">
            <strong>Chemical Resistance:</strong> Shields against harmful
            chemicals and pollutants.
          </p>
          <p className="fs-5 mt-3">
            <strong>UV and Oxidation Resistance:</strong> Protects against sun
            damage and oxidation
          </p>
          <p className="fs-5 mt-3">
            <strong>Ease of Cleaning:</strong> Repels water and dirt, making
            cleaning a breeze
          </p>
        </div>
      </div>
    </div>
  );
};

const CeramicBenfits = () => {
  return (
    <div className="container mb-3">
      <TitleName title="BENEFITS OF" subtitle="CERAMIC COATING" />
      <div className="row">
        <div className="col-md-7">
          <img className="w-100" src={handcoating} alt="" />
        </div>
        <div className="col-md-5">
          <img
            // style={{ width: "470px", height: "292px" }}
            className="w-100 mt-1"
            src={handcoating2}
            alt="Ceramic Coating 3"
          />
          <img
            // style={{ width: "475px", height: "292px" }}
            className="w-100 mt-3"
            src={handcoating1}
            alt="Ceramic Coating 3"
          />
        </div>
      </div>
      <BenfitsData />
    </div>
  );
};

export default CeramicBenfits;
