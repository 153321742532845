import React from "react";

import { Helmet } from "react-helmet";

function CancellationAndRefundPolicy() {
  return (
    <>
      <Helmet>
        <title>Cancellation and Refund Policy | Caross</title>
        <meta
          name="description"
          content="Learn about Caross's cancellation and refund policy to understand the process and conditions for canceling services and requesting refunds."
        ></meta>
      </Helmet>
      <div className="container-fluid nav-margin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="my-4 red-t">Cancellation & Refund Policy</h3>
              <p className="my-4">No cancellations & Refunds are entertained</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancellationAndRefundPolicy;
