import { useContext, useEffect, useState } from "react";
import ChangeCar from "./change-car/ChangeCar";
import { AuthContext } from "../../AuthProvider";
import { addCarContext } from "../../context/AddCarProvider";
import Loader from "../../Loader";
import Toast from "../../Tost";
import Slider from "react-slick";
import Axios from "../../Axios";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ServiceCard from "../../components/ServiceCard";
import CategoryCardService from "../../components/CategoryCardService";

function Services() {
  const { userToken } = useContext(AuthContext);
  const { handleGetCars, loading, userCarDetails } = useContext(addCarContext);
  const [defaultCar, setDefaultCar] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesFQS, setCategoriesFQS] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [allCategoriesData, setAllCategoriesData] = useState([]);
  const [allServicesData, setAllServicesData] = useState([]);
  const [changes, SetChanges] = useState(true);

  const servicesCache = {};

  const fetchServicesData = async (catId) => {
    if (servicesCache[catId]) {
      const cachedData = servicesCache[catId];
      setAllServicesData(cachedData.products);
      setCategoriesFQS(cachedData.faqs);
      setMetaTitle(cachedData.meta_title || "Default Meta Title");
      setMetaDescription(
        cachedData.meta_description || "Default Meta Description"
      );
      return;
    }

    try {
      setIsLoading(true);
      const response = await Axios.get(
        `https://caross.in/admin/api/user/get_all_products?cat_id=${catId}`
      );
      if (response.status === 200) {
        const data = response.data;
        console.log("API Response:", data);
        servicesCache[catId] = data;
        setAllServicesData(data.products);
        setCategoriesFQS(data.faqs);
        setMetaTitle(data.meta_title || "Default Meta Title");
        setMetaDescription(data.meta_description || "Default Meta Description");
        console.log("Meta Title:", data.meta_title);
        console.log("Meta Description:", data.meta_description);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const categoryId = location.pathname.split("/").pop();
    fetchServicesData(categoryId);

    handleGetCars(userToken);
    getDefaultCar();
    getAllCategories();
  }, [location.pathname, userToken]);

  const getAllCategories = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.get("get_all_categories");
      if (response.status === 200) {
        const data = response?.data;
        setAllCategoriesData(data?.categories.reverse());
        Toast(data?.message, response?.status);
      }
    } catch (err) {
      const error = err?.response?.data;
      Toast(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMeta = (title, description) => {
    setMetaTitle(title);
    setMetaDescription(description);
  };

  const handleButtonClick = (category) => {
    const categoryId = category.id;
    fetchServicesData(categoryId);
    switch (category.name) {
      case "detailing":
        updateMeta(
          "Detailing Services - Caross",
          `Discover a wide range of high-quality car accessories & essentials at Caross. We offer
          a comprehensive suite of services, including Denting, Painting, Car Wash, Auto
          detailing & more, to help you keep your car looking and performing its best.`
        );
        break;
      case "paint-protection":
        updateMeta(
          "Paint Protection - Caross",
          "Explore our paint protection services at Caross.in. Protect your car's paint from damage and keep it looking new."
        );
        break;
      case "services":
        updateMeta(
          "Services - Caross",
          "Explore our services at Caross.in. From detailing to paint protection, we offer a wide range of services for your car."
        );
        break;
      case "accessories":
        updateMeta(
          "Accessories - Caross",
          "Explore our car accessories at Caross.in. Enhance your car's functionality and style with our range of accessories."
        );
        break;
      default:
        updateMeta(
          "Welcome to Caross Detailing",
          "Welcome to Caross Detailing. We offer a wide range of car detailing and protection services."
        );
    }
  };

  const getDefaultCar = async () => {
    try {
      setIsLoading(true);
      if (userToken) {
        const response = await Axios.get("get_user_car", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (response.status === 200) {
          const data = response?.data;
          setDefaultCar(data?.car);
        }
      }
    } catch (err) {
      const error = err.response.data;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetCars(userToken);
    getDefaultCar();
  }, [userToken]);

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      {loading && <Loader />}

      <div className="container position-relative py-5 mt-5">
        <div
          className="d-block col-md-3 pb-4 position-lg-absolute"
          style={{ right: "0vw", top: 0 }}
        >
          <div style={{ visibility: "hidden", height: "72px" }}></div>
          {defaultCar?.brand ? <ChangeCar userCarDetails={defaultCar} /> : null}
        </div>

        <div className={defaultCar?.brand ? "col-12 col-md-9" : "col-12"}>
          <div className="category-slider px-5">
            {allCategoriesData.length > 4 ? (
              <Slider {...settings}>
                {allCategoriesData?.map((logo, idx) => (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    key={idx + 1}
                  >
                    <CategoryCardService
                      logo={logo}
                      SetChanges={SetChanges}
                      handleButtonClick={() => handleButtonClick(logo)}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <div
                className="row justify-content-center d-md-flex pb-2 pb-md-1 mb-5"
                style={{ background: "white" }}
              >
                {allCategoriesData?.map((logo) => (
                  <CategoryCardService
                    logo={logo}
                    SetChanges={SetChanges}
                    handleButtonClick={() => handleButtonClick(logo)}
                  />
                ))}
              </div>
            )}
          </div>

          <ServiceCard />
        </div>

        {categoriesFQS?.length > 0 && (
          <div className="col-12 mt-4 mb-4">
            <h1 className="mb-4 text-center fw-bold">
              FREQUENTLY{" "}
              <span style={{ color: "#b8242a" }}>ASKED QUESTIONS</span>
            </h1>
            <div className="accordion" id="faqAccordion">
              {categoriesFQS.map((faq, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index}`}
                    >
                      {faq.ques}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Services;
